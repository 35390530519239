
<template>
  <!-- ${item.subType} -->
  <component :is="`textCardModule_1`" :item="item" :class="[`item-textcard-theme-1`]"
    :textCardConfig="themeConfig.textCardConfig" :themeConfig="themeConfig"
    @customClick="sendFather()" />
</template>

<script>
import { mapGetters } from 'vuex'

const textCardModule_1 = () => import('@/pages/modules/textcard/textCardModule_1.vue') //代码切割
// const textCardModule_2 = () => import('@/pages/modules/textcard/textCardModule_2.vue') //代码切割

export default {
  components: {
    textCardModule_1,
    // textCardModule_2,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      themeConfig: 'themeConfig',
    }),
  },
  methods: {
    sendFather () {
      this.$emit('customClick');
    }
  },
};
</script>
